import { createContext, useContext } from 'react';

const WebAppContext = createContext({
  runningInWebView: false,
  runningInIOS: false,
  runningInAndroid: false,
});

export function WebAppProvider({
  children,
  runningInWebView,
  runningInIOS,
  runningInAndroid,
}) {
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <WebAppContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ runningInWebView, runningInIOS, runningInAndroid }}
    >
      {children}
    </WebAppContext.Provider>
  );
}

export const useWebApp = () => {
  const { runningInWebView } = useContext(WebAppContext);
  return runningInWebView;
};

export const useIOS = () => {
  const { runningInIOS } = useContext(WebAppContext);
  return runningInIOS;
};
