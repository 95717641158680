import { UPDATEFILTER } from '../types/schoolTypes';

const initialState = {
  languageId: 1,
  programId: 1,
  courseId: '',
  courseTypeId: 0,
  countryId: 0,
  cityId: '',
  accommodationId: 0,
  lowestPrice: false,
  accommodationType: 'none',
  accommodationBedroomType: null,
  weekRange: 2,
  category: '',
  updateFilter: true,
};

const schoolFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATEFILTER:
      return { ...action.payload };
    default:
      return state;
  }
};

export default schoolFilterReducer;
