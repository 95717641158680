import { combineReducers } from 'redux';
import schoolFilterDataReducer from './schoolFilterDataReducer';
import schoolFilterReducer from './schoolFilterReducer';
import schoolDetailReducer from './schoolDetailReducer';
import checkoutReducer from './checkoutReducer';
import countryReducer from './countryReducer';
import packageReducer from './packageReducer';
import studentReducer from './studentReducer';

export default combineReducers({
  schoolFilterDataReducer,
  schoolFilterReducer,
  checkoutReducer,
  schoolDetailReducer,
  countryReducer,
  packageReducer,
  studentReducer,
});
