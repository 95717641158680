import { ADD_STUDENT, ADD_LEAD, UPDATE_LEAD } from '../types/studentTypes';

const initialState = {
  lead: {},
  student: {},
  subscriptionLead: {},
};

const studentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_STUDENT:
      return {
        ...state,
        student: { ...action.payload.student },
      };
    case ADD_LEAD:
      return {
        ...state,
        lead: { ...action.payload },
      };
    case UPDATE_LEAD:
      return {
        ...state,
        lead: { ...state.lead, ...action.payload },
      };
    default:
      return { ...state };
  }
};

export default studentReducer;
