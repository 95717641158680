import { HYDRATE } from 'next-redux-wrapper';
import {
  FETCH_ALLCOUNTRIESTOFILTER,
  FETCH_COUNTRIES,
} from 'store/types/countryTypes';

const initialState = {
  data: '',
};

const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return action.payload.countryReducer;
    case FETCH_ALLCOUNTRIESTOFILTER:
      return action.payload;
    case FETCH_COUNTRIES:
      return action.payload;
    default:
      return state;
  }
};

export default countryReducer;
