import { HYDRATE } from 'next-redux-wrapper';
import { UPDATEFILTERDATA } from '../types/schoolTypes';

const initialState = {};

const schoolFilterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return action.payload.schoolFilterDataReducer;
    case UPDATEFILTERDATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default schoolFilterDataReducer;
