import { HYDRATE } from 'next-redux-wrapper';
import {
  FETCH_SCHOOL_DETAILS,
  UPDATE_COURSE,
  UPDATE_ACCOMMODATION,
  UPDATE_ADDITIONAL_SERVICE,
  UPDATE_TOTAL_PRICE,
  UPDATE_INSURANCE,
  OPEN_CLOSE_LEAD_DIALOG,
  SET_DEPOSIT,
} from '../types/schoolDetailTypes';

const initialState = {
  currency: {
    symbol: '',
    name: '',
    id: 0,
  },
  fareDateValidUntil: null,
  comments: [],
  accommodationGroups: [
    {
      name: '',
      accommodations: [
        {
          accommodationName: {
            name: '',
            id: 0,
          },
          supplementsFree: false,
          registrationFee: 0,
          registrationFeeWithDiscount: 0,
          supplementPrice: 0,
          weeksQuantityFree: 0,
          priceRanges: [
            {
              exchangeRateUsed: 0,
              price: 0,
              priceExchanged: 0,
              priceWithDiscount: 0,
              priceWithDiscountExchanged: 0,
              priceByExtraNight: 0,
              priceByExtraNightExchanged: 0,
              rangeFrom: 0,
              rangeTo: 0,
              bedroom: '',
              id: 0,
            },
          ],
          location: {
            name: '',
            slugName: '',
            city: {
              name: '',
              slugName: '',
              country: {
                name: '',
                slugName: '',
                id: 0,
              },
              id: 0,
            },
            id: 0,
          },
          description: '',
          meal: '',
          type: '',
          id: 0,
        },
      ],
      id: 0,
    },
  ],
  additionalServices: [
    {
      description: '',
      price: 0,
      required: false,
      id: 0,
    },
  ],
  courses: [
    {
      bookMaterialChargedBefore: false,
      bookMaterialForEachWeeks: 0,
      bookMaterialMaximumWeeks: 0,
      bookMaterialNote: '',
      bookMaterialPrice: 0,
      bookMaterialPriceDiscountOffered: 0,
      bookMaterialPriceWithDiscount: 0,
      classesQuantity: '',
      language: {
        description: '',
        id: 0,
      },
      priceRanges: [
        {
          exchangeRateUsed: 0,
          price: 0,
          priceExchanged: 0,
          priceWithDiscount: 0,
          priceWithDiscountExchanged: 0,
          priceByExtraNight: 0,
          priceByExtraNightExchanged: 0,
          rangeFrom: 0,
          rangeTo: 0,
          bedroom: '',
          id: 0,
        },
      ],
      program: {
        name: '',
        id: 0,
      },
      promotions: [
        {
          id: 0,
        },
      ],
      registrationFee: 0,
      registrationFeeWithDiscount: 0,
      rules: '',
      schedule: '',
      startDates: ['2020-01-16T17:47:43.637Z'],
      supplements: [
        {
          description: '',
          startDate: '2020-01-16T17:47:43.637Z',
          endDate: '2020-01-16T17:47:43.637Z',
          price: 0,
          rangeFrom: 0,
          rangeTo: 0,
          id: 0,
        },
      ],
      supplementsFree: false,
      type: '',
      workPermission: false,
      bookMaterial: {
        price: 0,
        forEachWeeks: 0,
        maximumWeeks: 0,
        chargedBefore: false,
        note: '',
        id: 0,
      },
      weeksQuantityFree: 0,
      supplementPrice: 0,
      about: '',
      id: 0,
    },
  ],
  location: {
    name: '',
    slugName: '',
    city: {
      name: '',
      slugName: '',
      country: {
        name: '',
        slugName: '',
        id: 0,
      },
      id: 0,
    },
    id: 0,
  },
  rating: {},
  braziliansPercentage: 0,
  classesQuantity: 0,
  minutesPerClass: 0,
  studentsAverageAge: 0,
  studentsMinimumAge: 0,
  studentsPerClass: 0,
  yearFounded: 0,
  about: '',
  documentNumber: '',
  email: '',
  fantasyName: '',
  imagePath: '',
  legalName: '',
  ownerContact: '',
  ownerName: '',
  ownerTelephone: '',
  siteURL: '',
  slugName: '',
  telephone: '',
  whyShouldYouStudyHere: '',
  id: 0,
  needAccommodation: true,
  openDialogLead: false,
  deposit: 0,
};

const schoolDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return action.payload.schoolDetailReducer;
    case FETCH_SCHOOL_DETAILS:
      return { ...state, ...action.payload };
    case UPDATE_COURSE:
      return { ...state, courses: action.payload };
    case UPDATE_ACCOMMODATION:
      return { ...state, accommodationGroups: action.payload };
    case UPDATE_ADDITIONAL_SERVICE:
      return { ...state, additionalServices: action.payload };
    case SET_DEPOSIT:
      return { ...state, deposit: action.payload };
    case UPDATE_INSURANCE:
      return {
        ...state,
        location: {
          ...state.location,
          city: {
            ...state.location.city,
            country: {
              ...state.location.city.country,
              insurances: action.payload,
            },
          },
        },
      };
    case UPDATE_TOTAL_PRICE:
      return { ...state, total: action.payload };
    case OPEN_CLOSE_LEAD_DIALOG:
      return { ...state, openDialogLead: action.payload };
    default:
      return { ...state };
  }
};

export default schoolDetailReducer;
