import {
  FETCH_CHECKOUT_INFO,
  UPDATE_BUYER,
  ADD_STUDENT,
} from '../types/checkoutTypes';

const initialState = {
  hash: '',
  school: {
    name: '',
    address: '',
    id: 0,
  },
  courses: [
    {
      type: '',
      startDate: '',
      weekQuantity: 0,
      registrationFee: 0,
      price: 0,
      id: 0,
    },
  ],
  accommodations: [
    {
      type: '',
      bedroomType: '',
      meal: 0,
      startDate: '',
      endDate: '',
      price: 0,
      id: 0,
    },
  ],
  additionalServices: [
    {
      type: '',
      price: 0,
      id: 0,
    },
  ],
  insurances: [
    {
      type: '',
      price: 0,
      id: 0,
    },
  ],
  totalValue: 0,
  expired: false,
  buyer: {},
};

const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHECKOUT_INFO:
      return { ...action.payload };

    case UPDATE_BUYER:
      return {
        ...state,
        ...action.payload,
        buyer: { ...action.payload.buyer },
      };
    case ADD_STUDENT:
      return {
        ...state,
        student: { ...action.payload },
        step: action.payload.step,
      };
    default:
      return { ...state };
  }
};

export default checkoutReducer;
