import { HYDRATE } from 'next-redux-wrapper';
import { FETCH_PACKAGES, FETCH_PACKAGE } from 'store/types/packageTypes';

const initialState = {
  allPackages: [],
  showMoreButton: true,
  detail: {},
};

const packageReducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return action.payload.packageReducer;
    case FETCH_PACKAGES:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_PACKAGE:
      return { ...state, detail: { ...action.payload } };
    default:
      return { ...state };
  }
};

export default packageReducer;
